.assignmentDetails-titleContainer {
  font-weight: 500;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
  text-decoration: none;
}

.assignmentDetails-filterBlocks {
  display: flex;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
}

.assignmentDetails-radioButton {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.assignmentDetails-radioButtonText {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
}

.assignmentDetails-filterDropdownBlock {
  display: flex;
  margin-right: 20px;
  align-items: center;
  border: 0.5px solid rgba(0, 65, 113, 0.5);
  border-radius: 5px;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.assignmentDetails-downArrow {
  height: 10px;
  width: 20px;
  margin-left: 20px;
}

.assignmentDetails-addNewText {
  margin-left: 8px;
  color: #fff;
  text-decoration: none;
}

.assignmentDetails-addButton {
  display: flex;
  margin-left: auto;
  align-items: center;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  /* padding-right: 19px;
  padding-left: 19px;
  padding-top: 8px;
  padding-bottom: 8px; */
  background-color: #004171;
  text-decoration: none;
}

.assignmentDetails-body {
  margin-top: 30px;
  border-radius: 5px;
  padding-left: 43px;
  padding-top: 23px;
  padding-bottom: 30px;
  background-color: #fff;
}

.assignmentDetails-bodyPanelistId {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #000000;
}

.assignmentDetails-bodyExpiryDate {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #61b687;
  margin-bottom: 24px;
}

.assignmentDetails-bodySerrator {
  border-bottom: 0.5px solid rgba(58, 58, 58, 0.5);
  width: 95%;
}

.assignmentDetails-bodyTitle1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #004171;
  margin-top: 24px;
  margin-bottom: 10px;
}

.assignmentDetails-bodyTitleDetail {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
}
.assignmentDetails-bodyFilterContainer {
  display: flex;
}

.assignmentDetails-bodyRadioContainer {
  display: flex;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
}

.assignmentDetails-bodyRadioIcon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.assignmentDetails-bodyTitle2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #004171;
  margin-top: 30px;
}

.assignmentDetails-bodyImagesContainer {
  margin-bottom: 30px;
}

.assignmentDetails-imageContainer {
  margin-top: 10px;
  display: flex;
}

.assignmentDetails-bodyImage {
  width: 135px;
  height: 112px;
  margin-right: 15px;
  cursor: pointer;
}

.assignmentDetails-remainingImageContainer {
  width: 135px;
  height: 112px;
  display: flex;
  background-color: #bfffd8;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.assignmentDetails-imageCount {
  text-align: center;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
}

.assignmentDetails-seeAll {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: #004171;
}

/* .assignmentDetails-remainingImageInnerContainer{
display: inline-block;
} */
