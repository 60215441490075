.modal-overlay {
  background-color: rgba(0, 0, 0, 0.69);
  width: 100vw;
  height: 100vh;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  overflow: auto;
}
.modal-close-btn {
  color: #000;
  background-color: rgba(0, 0, 0, 0.69);
  position: absolute;
  padding: 7px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
