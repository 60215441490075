.paginationPrev,
.paginationNext {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;

  cursor: pointer;
  margin: 0 5px;
  display: flex;
}
.pagination-disable {
  color: #9d9fa2;
}
.paginationItem,
.paginationBreak {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 26px;
  margin: 0 5px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #3a3a3a;
}
.paginationItem.active {
  background: #3cba84;
  border-radius: 50%;
  color: #fff;
}
.paginationItem:hover {
  background: #3cba84;
  border-radius: 50%;
  color: #fff;
}
.pagination-navIcon {
  font-size: 40px;
  margin: 0 10px;
}
