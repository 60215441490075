/* base */
.approveRejectBtn {
  padding: 6px 10px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.25rem 0.125rem;
  text-align: center;
}
.approveRejectBtn-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
}
.approveRejectBtn-icon {
  width: 18px;
  height: 13px;
}

/* custom */
.approveBtn {
  background: #e9ffea;
  border: 1px solid #51b955;
}
.approveBtn-text {
  color: #51b955;
}

.rejectBtn {
  background: #ffebeb;
  border: 1px solid #ec5c5c;
}

.rejectBtn-text {
  color: #ec5c5c;
}
