.rewards-titleContainer {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
  color: #3a3a3a;
}

.rewards-filterContainer {
  margin-bottom: 30px;

  display: flex;
}

.rewards-searchContainer {
  padding-left: 15px;

  padding-top: 10px;

  padding-bottom: 10px;

  border-radius: 5px;

  display: flex;

  flex: 1;

  margin-right: 5px;

  border: 0.5px solid rgba(0, 65, 113, 0.14);
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  cursor: pointer;
}

.rewards-searchInput {
  margin-left: 10px;

  font-weight: 400;

  font-size: 18px;

  color: #3a3a3a;

  line-height: 21px;

  width: 100%;

  margin-right: 10px;

  border: none;
  background: transparent;
}

.rewards-searchInput:focus {
  outline-width: 0;
}

.rewards-searchIcon {
  height: 20px;

  width: 20px;
}

.rewards-filterBlocks {
  display: flex;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
}

.rewards-radioButton {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.rewards-radioButtonText {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
}

/* .rewards-dataContainer {
  margin: 60px 0;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #dcdddf5c;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
}

.rewards-dataContainer thead {
  color: #004172;
}

.rewards-dataContainer thead th {
  padding: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.rewards-dataContainer tbody {
  color: #3a3a3a;
}

.rewards-dataContainer tbody td {
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
*/
/* .reward-dataRating {
  padding: 0 15px 0 0;
  cursor: pointer;
} */
.reward-editIcon {
  cursor: pointer;
}

.rewardsModal-body {
  background-color: #f4fbff;
  min-width: 900px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
}
.rewardsModal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;

  color: #3a3a3a;
  margin-bottom: 34px;
}
.rewardsModal-submitBtn {
  background-color: #004172;
  padding: 30px;
  border: none;
  border-radius: 5px;
  color: #fff;
  min-width: 231px;
  /* margin: 10px; */

  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
}

.rewardsModal-input {
  border: 0.5px solid rgba(0, 65, 113, 0.69);
  border-radius: 5px;
  padding: 15px;

  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  width: 209px;
  color: #9d9fa2;

  margin-bottom: 40px;
  background-color: transparent;
}
.rewards-modal-close-btn {
  color: #000;
  background-color: rgba(0, 0, 0, 0.69);
  position: absolute;
  padding: 11px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.rewardsModal-subtitle {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3a3a3a;
}
.rewardModal-input {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-align: center;

  color: #9d9fa2;
  padding: 7px 15px;
  border-bottom: 1px solid #fff;
  width: 125px;
}
.rewardModal-input::placeholder {
  color: #9d9fa2;
}
.rewardModal-input:focus {
  outline: none;
  border-bottom: 1px solid #9d9fa2;
}
.newBtn {
  display: flex;
  flex: 1;

  justify-content: space-between;
}
.download {
  cursor: pointer;
  text-decoration: none;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  /* color: #3a3a3a; */
  color: #004171;
}
.downloadResponse {
  text-decoration: none;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  /* color: #3a3a3a; */
  color: #004171;
}
