.assignment-titleContainer {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
}

.assignment-radioButton {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.assignment-radioButtonText {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
}

.assignment-filterBlocks {
  display: flex;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
}

.assignment-filterDropdownBlock {
  display: flex;
  margin-right: 20px;
  align-items: center;
  border: 0.5px solid rgba(0, 65, 113, 0.5);
  border-radius: 5px;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.assignment-addButton {
  display: flex;
  margin-left: auto;
  align-items: center;
  border-radius: 5px;
  padding: 10px 30px;
  font-size: 18px;
  /* padding-right: 19px;
  padding-left: 19px;
  padding-top: 8px;
  padding-bottom: 8px; */
  background-color: #004171;
  text-decoration: none;
}

.assignment-downArrow {
  height: 10px;
  width: 20px;
  margin-left: 20px;
}

.assignment-addNewText {
  margin-left: 8px;
  color: #fff;
  text-decoration: none;
}

.assignment-listContainer {
  display: flex;
  margin-top: 20px;
  background-color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.assignment-detailBlock {
  flex: 5;
  /* border: 0.5px solid rgba(0, 65, 113, 0.5); */
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
/* .assignment-detailBlock::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  margin: 10px;
} */

.assignment-responseBlock {
  flex: 2;
}

.assignment-assignmentId {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.assignment-descriptionTitle {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.assignment-descriptionDetails {
  color: #3a3a3a;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.assignment-saperator1 {
  margin-right: 20px;
  margin-left: 20px;
  height: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.assignment-descriptionText2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #61b687;
}

.assignment-responseBlock {
  align-self: center;
  margin-left: 30px;
}

.assignment-responseInnerBlock {
  display: inline-block;
  border: 0.5px solid rgba(0, 65, 112, 0.69);
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 12px;
  padding-right: 15px;
  padding-bottom: 12px;
}

.assignment-responseInnerBlock2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assignment-responseText {
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #004171;
}

.assignment-listMainContainer {
  margin: 30px 0;
}
