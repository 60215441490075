.surgeryReferLeads-titleContainer {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 30px;
  color: #3a3a3a;
}
.surgeryReferLeads-filterContainer {
  margin-bottom: 30px;

  display: flex;
}

.surgeryReferLeads-searchContainer {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  border: 0.5px solid rgba(0, 65, 113, 0.14);
}

.surgeryReferLeads-searchInput {
  margin-left: 10px;

  font-weight: 400;

  font-size: 18px;

  color: #3a3a3a;

  line-height: 21px;

  width: 100%;

  margin-right: 10px;

  border: none;
  background: transparent;
}

.surgeryReferLeads-searchInput:focus {
  outline-width: 0;
}

.surgeryReferLeads-searchIcon {
  height: 20px;

  width: 20px;
}
.surgeryReferLeads-cardContainer {
  display: flex;
  position: relative;
  padding: 20px;
  padding-bottom: 25px;
}
.surgeryReferLeads-cardInfo {
  background: #e6f6ff;
  border: 0.5px solid rgba(0, 65, 113, 0.44);
  border-radius: 5px;
  margin: 0 20px;
  padding: 13px;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;
}
.surgeryLeads-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.surgeryLeads-cards {
  width: calc(50% - 10px);
  background: #ffffff;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}
.surgeryReferLeads-cardTitle {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  text-transform: capitalize;
}
.surgeryReferLeads-phoneNumber,
.surgeryReferLeads-age {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-left: 5px;
}
.surgeryReferLeads-slab {
  border: 0.5px solid rgba(0, 65, 113, 0.14);
  padding: 7px;
  font-size: 16px;
  width: 200px;
  border-radius: 5px;
  height: fit-content;
  margin: auto;
  margin-left: 0;
}
/* .surgeryReferLeads-slab option:checked {
  background: linear-gradient(#d6d6d6, #d6d6d6);
  background-color: #004171 !important; 
  color: #fff !important; 
  /* for IE 
} */
