/* Add this CSS to your stylesheets or style block */
.approve-modalt {
  width: 1231px;
  min-height: 333px; /* Set minimum height */
  max-height: 90vh; /* Set a max height, for example, 90% of the viewport height. Adjust as needed. */
  overflow-y: auto; /* If content exceeds max-height, a scrollbar appears */

  top: 301px;
  left: 184px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 20px;
  z-index: 9999;
}
.modaltitle1 {
  margin-top: 8px;
  margin-left: 4px;
  font-size: 22px;
  line-height: 26.07px;
  color: #000000;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}

.swasthyamitradetails {
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  padding-left: 20px;
  flex-grow: 1;
}

/* Create the faded background effect */
.modal-containert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modaltitle {
  margin-top: 8px;
  margin-left: 4px;
  font-size: 22px;
  line-height: 26.07px;
  color: #000000;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
}
.requestamount {
  color: #63be39;
  margin-left: 490px;
  margin-top: 12px;
}
.cardtwoTitletrans {
  margin-top: 5px;
  font-size: 22px;
  line-height: 2px;
  color: #000000;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}

.transtdetails {
  margin-top: 40px;
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  margin-bottom: -76px;
}

.Detailstrans {
  padding-left: 60px;
  margin-bottom: 100px;
  flex-direction: column;
}

.headingtrans {
  margin-left: 8px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.33px;
  letter-spacing: 0.1px;
}

.subheadingtrans {
  margin-top: 2px;
  margin-left: 2px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.33px;
  letter-spacing: 0.1px;
}

.rowsecond {
  flex-direction: column;
  margin-top: 70px;
  margin-left: 10px;
  width: 1013px;
  height: 209px;
  border-radius: 5px;
  background: #cff1ff1f;
}

.part1 {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  margin-left: 93px; /* Adds spacing outside the element */
  padding: 10px;
}
.subpart1 {
  flex-direction: column;
}

.part2 {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  margin-left: 93px;

  padding: 10px;
}
.center-button {
  margin-top: 20px;
  margin-left: 20px;
  width: 150px;
  height: 50px;
  border: 1px solid #004171b0; /* Add border with 1px width and the desired color */
  border-radius: 5px;
  background-color: #ffffff; /* You can change the background color to your desired color */
  color: #3a3a3a; /* You can change the text color to your desired color */
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.crossbutton {
  margin-left: 322px;
  cursor: pointer;
}

.MuiPickersModal-dialogRoot {
  position: absolute;
  z-index: 900; /* Adjust as needed */
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  margin-left: 361px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-left: 723px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  margin-left: 367px;
}

.datepick {
  margin-left: 593px;
}

.detailspro {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  padding-top: 30px;
}

.Icontran {
  width: 13px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.detailsprosecond {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  padding-top: 10px;
}
.detailsprothird {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  padding-top: 15px;
}

.selected {
  background-color: #004171;
  color: #ffffff;
}

#section1::selected {
  color: #ffffff;
  font-weight: 500; /* This will change the text color to white when selected */
}

.section {
  border: 1px solid #004171; /* 1px wide solid black border */
  width: 407.49px;
  height: 54px;
  top: 168px;
  left: 145px;
  margin-top: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px;
  display: flex; /* Enable flexbox for this container */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center;
  color: #004171;
  cursor: pointer;

  /* You may also want to add position if top and left are to be used. */
}

.section1 {
  border: 1px solid #004171; /* 1px wide solid black border */
  width: 407.49px;
  height: 54px;
  top: 168px;
  left: 145px;
  margin-top: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0px;
  display: flex; /* Enable flexbox for this container */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center;
  color: #004171;
  cursor: pointer;
}

.section1.selected {
  background-color: #004171;
  color: #ffffff;
  font-weight: 500;
}

.section2 {
  border: 1px solid #004171; /* 1px wide solid black border */
  width: 407.49px;
  height: 54px;
  top: 168px;
  left: 145px;
  margin-top: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0px;
  display: flex; /* Enable flexbox for this container */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center;
  color: #004171;
  cursor: pointer;
}

.section2.selected {
  background-color: #004171;
  color: #ffffff;
  font-weight: 500;
}

.filterSection1 {
  margin-left: 26px;
  border: 1px solid #c8c8c8;
  margin-top: 7px;

  width: 141.61798095703125px;
  height: 38.662837982177734px;

  top: 97px;
  left: 441.171875px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.filterSection2 {
  width: 141.61798095703125px;
  height: 38.662837982177734px;

  top: 97px;
  left: 441.171875px;
  border-radius: 30px;
  margin-top: 7px;

  border: 1px solid #c8c8c8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Icontrans {
  width: 13px;
  height: 30px;
  margin-left: 10px;
  margin-top: 24px;
}

.requestdate {
  color: #63be39;
  margin-left: 820px;
  margin-top: 12px;
}

.clock {
  width: 20.52px;
  height: 20px;
  margin-left: 10px;
}

.paymenttext {
  margin-left: 7px;
  margin-top: 28px;
}

.statusti {
  margin-left: 570px;
  margin-top: 12px;
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
}

.closingbutton {
  width: 150px;
  height: 49px;
  top: 881px;
  left: 164px;
  border-radius: 5px;
  border: 0.5px solid #004171;
  background-color: #206191;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  z-index: 100;
}

.comissionTable {
  /* width: 1120px; */ /* Commented out */
  max-width: 1120px; /* Optional */
  height: 1200px;
  top: 268px;
  left: 164px;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  margin-left: 25px;
}

.headingsdiv {
  width: 1120px;
  height: 55px;
  top: 268px;
  left: 164px;
  background-color: #dcdddf5c;
  display: flex; /* This makes the children elements align in a row */
  align-items: center;

  /* margin-left: 20px; */
}

.maindiv {
  margin-top: 10px;
  margin-bottom: 10px;
}

.commsionSection1 {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}
.commsionSection2 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}
.commsionSection3 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}
.commsionSection4 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}

/* .horizontalline {
  border-right: 6px solid green;
  width: 55px;
} */

.sectionHeading {
  width: 129px;
  height: 24px;
  top: 345px;
  left: 660px;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #3a3a3a;
  margin-left: 500px;
}

.horizontal {
  border-left: 1px solid #dcdddfad;
  height: 53px;
}

.sectionheadingsdiv {
  width: 1120px;
  height: 55px;
  border-top: 0.5px solid #dcdddfad;
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  margin-top: 10px;
  border-bottom: 0.5px solid #dcdddfad;
}

.commsionSubSection1 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}

.commsionSubSection2 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}
.commsionSubSection3 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}
.commsionSubSection4 {
  width: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #004171;
}

.subsectionheadingsdiv {
  margin-top: -1px;
  width: 1120px;
  height: 55px;
  border-top: 0.5px solid #dcdddfad;
  display: flex; /* This makes the children elements align in a row */
  align-items: center;

  border-bottom: 0.5px solid #dcdddfad;
}

.nodatafound {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
