.iconDropDownContainer {
  z-index: 2;
  position: relative;
}

.iconDropDownInput {
  min-width: 194px;
  width: 466px;

  padding: 14px 23px 14px 55px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 65, 114, 0.55);

  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3a3a3a;
  cursor: pointer;
  /* background-color: #; */
}
.iconDropDownOptionsContainer {
  position: absolute;
  background-color: #ffffff;
  width: 100%;

  padding-top: 10px;
  box-shadow: 1px 22px 40px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 300px;
  overflow-y: scroll;
}
.iconDropDownOptiontop {
  box-shadow: 1px -22px 40px rgb(0 0 0 / 15%);
  bottom: 57px !important;
}

.iconDropDownListContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9d9fa2;
  padding: 7px;
  cursor: pointer;
  padding-left: 15px;
}
.iconDropDownListContainer:hover {
  background-color: #f5f5f5;
}

.iconDropDownListContainer > img {
  height: 22px;
  width: 22px;
  margin-right: 14px;
  margin-top: 2px;
  border-radius: 50%;
}

/* .iconOverlayForDropdown {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
} */
