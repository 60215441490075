.dropdownTopContainer {
  z-index: 2;
  position: relative;
}

.dropdownContainer {
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin-left: 5px;
  border: 0.5px solid rgba(0, 65, 113, 0.14);
  align-items: center;
  cursor: pointer;
}

.dropdownOptionsContainer {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  padding-left: 15px;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  box-shadow: 1px 22px 40px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.dropDownListContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9d9fa2;
  margin-bottom: 16px;
  cursor: pointer;
}

.dropDownListContainer > img {
  height: 18px;
  width: 18px;
  margin-right: 14px;
  margin-top: 2px;
}

.dropdownDownArrow {
  height: 10px;
  width: 20px;
}

.dropdownText {
  flex: 9;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
}

.dropdownArrowContainer {
  padding-left: 10px;
  flex: 1;
}

.overlayForDropdown {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
