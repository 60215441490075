.profilepage-breadcrumb {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

  margin-bottom: 30px;
}
.profilepage-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

  margin-bottom: 15px;
}
.profileChane-container {
  display: flex;
  gap: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcdddf99;
}
.profileChange-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.1px;

  color: #3a3a3a;

  margin: 7px 0;
}

.profileChane-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  color: #3a3a3a;

  cursor: pointer;
}
.profileChangeBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;

  padding: 15px 30px;
  border-radius: 5px;
  height: fit-content;
  border: 0.5px solid rgba(0, 65, 114, 0.8);
  cursor: pointer;
  margin: 8px 0;
}
.profileChange-cancelBtn {
  margin-left: auto;
  background-color: transparent;
  color: #3a3a3a;
}
.profileChange-saveBtn {
  background-color: #004172;
  color: #fff;
}
.profilePage-inputGroup {
  display: flex;
  padding: 15px 0;
  align-items: center;
}
.profilePage-inputBorder {
  border-bottom: 1px solid #dcdddf99;
}
.profilePage-inputLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3a3a3a;

  flex: 1;
}
.profilePage-inputField {
  min-width: 194px;
  width: 466px;

  padding: 14px 23px;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 65, 114, 0.55);

  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #3a3a3a;
  background: transparent;
}

.profilePage-inputIcon {
  position: absolute;
  display: flex;
  z-index: 1;
}

.profileCountryInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 35px;
}
.profilePage-countryIcon {
  border-radius: 50%;
}
